// Generated by Framer (1119174)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["E7KMD60aT"];

const serializationHash = "framer-5od9Z"

const variantClassNames = {E7KMD60aT: "framer-v-1m1u1sm"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, name1, width, ...props}) => { return {...props, tujKXfOPW: name1 ?? props.tujKXfOPW ?? "Fintech"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;name1?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, tujKXfOPW, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "E7KMD60aT", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-5od9Z", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1m1u1sm", className)} data-border data-framer-name={"Fintech"} layoutDependency={layoutDependency} layoutId={"E7KMD60aT"} ref={ref ?? ref1} style={{"--border-bottom-width": "2px", "--border-color": "rgba(142, 132, 255, 0.5)", "--border-left-width": "2px", "--border-right-width": "2px", "--border-style": "solid", "--border-top-width": "2px", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0FsdG9uZSBNZWRpdW0=", "--framer-font-family": "\"Altone Medium\", \"Altone Medium Placeholder\", sans-serif", "--framer-letter-spacing": "0px", "--framer-line-height": "120%", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-bd573de2-a99b-47a0-86f9-5c1241a6fd64, rgb(0, 71, 187)))"}}>Fintech</motion.p></React.Fragment>} className={"framer-1dnjjsa"} data-framer-name={"Almost before we kne"} fonts={["CUSTOM;Altone Medium"]} layoutDependency={layoutDependency} layoutId={"PfM3AiUna"} style={{"--extracted-r6o4lv": "var(--token-bd573de2-a99b-47a0-86f9-5c1241a6fd64, rgb(0, 71, 187))", "--framer-paragraph-spacing": "0px"}} text={tujKXfOPW} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-5od9Z [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5od9Z .framer-1n9vtvf { display: block; }", ".framer-5od9Z .framer-1m1u1sm { align-content: start; align-items: start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 6px 12px 6px 12px; position: relative; width: min-content; }", ".framer-5od9Z .framer-1dnjjsa { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-5od9Z .framer-1m1u1sm { gap: 0px; } .framer-5od9Z .framer-1m1u1sm > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-5od9Z .framer-1m1u1sm > :first-child { margin-left: 0px; } .framer-5od9Z .framer-1m1u1sm > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 83
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"tujKXfOPW":"name1"}
 * @framerImmutableVariables true
 */
const FramerAY3Y6g0Yz: React.ComponentType<Props> = withCSS(Component, css, "framer-5od9Z") as typeof Component;
export default FramerAY3Y6g0Yz;

FramerAY3Y6g0Yz.displayName = "category";

FramerAY3Y6g0Yz.defaultProps = {height: 31, width: 83};

addPropertyControls(FramerAY3Y6g0Yz, {tujKXfOPW: {defaultValue: "Fintech", displayTextArea: false, title: "Name", type: ControlType.String}} as any)

addFonts(FramerAY3Y6g0Yz, [{family: "Altone Medium", url: "https://framerusercontent.com/assets/oXFLBxnIL1ll2CakIOtszku3VQ.woff2"}])